var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Simple", "code-toggler": "" } },
    [
      _c(
        "vs-button",
        {
          on: {
            contextmenu: function ($event) {
              $event.preventDefault()
              return _vm.$refs.menu.open.apply(null, arguments)
            },
          },
        },
        [_vm._v("Right click on me")]
      ),
      _c("vue-context", { ref: "menu" }, [
        _c("li", [
          _c(
            "a",
            {
              staticClass: "flex items-center text-sm",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.optionClicked("New")
                },
              },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "PlusIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "ml-2" }, [_vm._v("New")]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "flex items-center text-sm",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.optionClicked("Open")
                },
              },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "FileIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "ml-2" }, [_vm._v("Open")]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "flex items-center text-sm",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.optionClicked("Save")
                },
              },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "SaveIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "ml-2" }, [_vm._v("Save")]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "flex items-center text-sm",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.optionClicked("As")
                },
              },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "SaveIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "ml-2" }, [_vm._v("Save As")]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "flex items-center text-sm",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.optionClicked("Close")
                },
              },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "XIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "ml-2" }, [_vm._v("Close")]),
            ],
            1
          ),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <vs-button @contextmenu.prevent="$refs.menu.open">Right click on me</vs-button>\n    <vue-context ref="menu">\n        <li>\n          <a href="#" @click="optionClicked(\'New\')" class="flex items-center text-sm">\n            <feather-icon icon="PlusIcon" svgClasses="w-5 h-5" />\n            <span class="ml-2">New</span>\n          </a>\n        </li>\n        <li>\n          <a href="#" @click="optionClicked(\'Open\')" class="flex items-center text-sm">\n            <feather-icon icon="FileIcon" svgClasses="w-5 h-5" />\n            <span class="ml-2">Open</span>\n          </a>\n        </li>\n        <li>\n          <a href="#" @click="optionClicked(\'Save\')" class="flex items-center text-sm">\n            <feather-icon icon="SaveIcon" svgClasses="w-5 h-5" />\n            <span class="ml-2">Save</span>\n          </a>\n        </li>\n        <li>\n          <a href="#" @click="optionClicked(\'As\')" class="flex items-center text-sm">\n            <feather-icon icon="SaveIcon" svgClasses="w-5 h-5" />\n            <span class="ml-2">Save As</span>\n          </a>\n        </li>\n        <li>\n          <a href="#" @click="optionClicked(\'Close\')" class="flex items-center text-sm">\n            <feather-icon icon="XIcon" svgClasses="w-5 h-5" />\n            <span class="ml-2">Close</span>\n          </a>\n        </li>\n    </vue-context>\n</template>\n\n<script>\nimport { VueContext } from \'vue-context\';\n\nexport default {\n    components: {\n        VueContext\n    },\n    methods: {\n        optionClicked(text) {\n            this.$vs.notify({\n                title: \'Action Clicked\',\n                text: text,\n                icon: \'feather\',\n                iconPack: \'icon-alert-circle\',\n                color: \'primary\'\n            })\n        }\n    }\n}\n</script>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }